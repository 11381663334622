<template>
  <router-layout>
    <div class="page">
      <van-loading color="#1989fa" type="spinner" class="loading" v-show="$store.state.loading" />
      <div class="tab">
        <van-tabs
          v-model="tabactive"
          animated
          sticky
          color="#567cf3"
          title-active-color="#567cf3"
          @change="tabsChange"
        >
          <van-tab :title="el.title" :name="List.i" v-for="el,i in List" :key="i">
            <div v-show="!$store.state.loading">
              <van-pull-refresh
                v-model="refreshing"
                @refresh="onRefresh"
                class="pull-refresh"
                v-show="hasData"
              >
                <van-list
                  v-model="loading"
                  :finished="finished"
                  finished-text="没有更多了"
                  @load="onLoad"
                  offset="10"
                >
                  <ul class="colUl" v-show="hasData">
                    <li v-for="(item,index) in el.list" :key="index" @click="toDetail(item)">
                      <div class="col_img" :style="{ 'background': 'url(' + item.image + ')'}"></div>
                      <div class="col_txt">
                        <div>
                          <h2>{{item.title}}</h2>
                          <p>{{item.description}}</p>
                        </div>
                        <div class="dt">{{item.time}}</div>
                      </div>
                    </li>
                  </ul>
                </van-list>
              </van-pull-refresh>
              <van-empty description="暂无内容" v-show="!hasData" />
            </div>
          </van-tab>
        </van-tabs>
      </div>
      <tabBar ref="tabbar" :tabbarActive="tabbarActive" />
    </div>
  </router-layout>
</template>
<script>
import tabBar from "@/components/tabbar/tabBar";
import authMixin from "@/mixin/mixin";
export default {
  data() {
    return {
      type: 1, //收藏的分类
      listType: "A",
      detailType: "", //跳转详情页判断
      tabactive: 0,
      tabbarActive: 2,
      refreshing: false, //下拉刷新
      loading: false, //上拉加载
      finished: false,
      hasData: true,
      page: 0,
      List: {
        A: {
          title: "房屋租售",
          list: []
        },
        B: {
          title: "人才招聘",
          list: []
        },
        C: {
          title: "求职简历",
          list: []
        },
        D: {
          title: "推广产品",
          list: []
        }
      }
    };
  },
  mixins: [authMixin],
  components: {
    tabBar
  },
  created() {
    this.$store.commit("setLoading", true);
    this.getData(this.listType);
  },
  methods: {
    tabsChange() {
      this.$store.commit("setLoading", true);
      this.type = this.tabactive + 1;
      if (this.type == 1) {
        this.listType = "A";
      } else if (this.type == 2) {
        this.listType = "B";
      } else if (this.type == 3) {
        this.listType = "C";
      } else if (this.type == 4) {
        this.listType = "D";
      }
      this.page = 0;
      this.List.A.list = [];
      this.List.B.list = [];
      this.List.C.list = [];
      this.List.D.list = [];
      this.finished = false;
      this.getData(this.listType);
    },
    toDetail(item) {
      //跳转详情
      if (this.listType == "A") {
        this.$router.push({
          path: "/HouseDetail",
          query: {
            id: item.id,
            infoType: this.type
          }
        });
      } else if (this.listType == "B") {
        this.$router.push({
          path: "/RecruitmentDetail",
          query: {
            id: item.id,
            infoType: this.type
          }
        });
      } else if (this.listType == "C") {
        this.$router.push({
          path: "/FindJobDetail",
          query: {
            id: item.id,
            infoType: this.type
          }
        });
      } else if (this.listType == "D") {
        this.$router.push({
          path: "/PromoteDetail",
          query: {
            id: item.id,
            infoType: this.type
          }
        });
      }
    },
    getData(listType) {
      this.$request({
        method: "get",
        url: "/cms/release/",
        params: {
          page: this.page,
          type: this.type
        }
      }).then(res => {
        console.log(res);
        this.$store.commit("setLoading", false);
        if (res.data && res.data.length) {
          this.hasData = true;
          res.data.forEach(item => {
            if (item.imgs) {
              //有图片
              if (item.imgs.includes(",")) {
                //两张图片
                if (item.description) {
                  let objdata = `{
                    "id":"${item.id}",
                    "title":"${item.title}",
                    "description":"${item.description}",
                    "image":"${item.imgs.split(",")[0]}",
                    "time":"${item.create_time.split(" ")[0]}"
                  }`;
                  this.List[listType].list.push(
                    JSON.parse(objdata.replace(/[\r\n+]/g, ""))
                  );
                } else {
                  let objdata = `{
                    "id":"${item.id}",
                    "title":"${item.title}",
                    "description":"",
                    "image":"${item.imgs.split(",")[0]}",
                    "time":"${item.create_time.split(" ")[0]}"
                  }`;
                  this.List[listType].list.push(
                    JSON.parse(objdata.replace(/[\r\n+]/g, ""))
                  );
                }
              } else {
                //一张图片
                if (item.description) {
                  let objdata = `{
                    "id":"${item.id}",
                    "title":"${item.title}",
                    "description":"${item.description}",
                    "image":"${item.imgs}",
                    "time":"${item.create_time.split(" ")[0]}"
                  }`;
                  this.List[listType].list.push(
                    JSON.parse(objdata.replace(/[\r\n+]/g, ""))
                  );
                } else {
                  let objdata = `{
                    "id":"${item.id}",
                    "title":"${item.title}",
                    "description":"",
                    "image":"${item.imgs}",
                    "time":"${item.create_time.split(" ")[0]}"
                  }`;
                  this.List[listType].list.push(
                    JSON.parse(objdata.replace(/[\r\n+]/g, ""))
                  );
                }
              }
            } else {
              ///没有图片
              if (item.description) {
                let objdata = `{
                "id":"${item.id}",
                "title":"${item.title}",
                "description":"${item.description}",
                "image": "${require("@/assets/images/noimg.jpg")}",
                "time":"${item.create_time.split(" ")[0]}"
                }`;
                this.List[listType].list.push(
                  JSON.parse(objdata.replace(/[\r\n+]/g, ""))
                );
              } else {
                let objdata = `{
                "id":"${item.id}",
                "title":"${item.title}",
                "description":"",
                "image": "${require("@/assets/images/noimg.jpg")}",
                "time":"${item.create_time.split(" ")[0]}"
                }`;
                this.List[listType].list.push(
                  JSON.parse(objdata.replace(/[\r\n+]/g, ""))
                );
              }
              /////
            }
          });
          this.loading = false;
          this.page = this.List[listType].list[
            this.List[listType].list.length - 1
          ].id;
        } else if (!res.data || (!res.data.length && this.page == 0)) {
          //暂无内容
          this.hasData = false;
        } else if (!res.data || res.data.length == 0) {
          this.finished = true;
        }
      });
    },
    onRefresh() {
      // this.$store.commit("setLoading", true);
      this.finished = false;
      this.loading = true;
      this.page = 0;
      this.List[this.listType].list = [];
      this.getData(this.listType);
      this.refreshing = false;
      console.log("刷新");
    },
    onLoad() {
      this.getData(this.listType);
    }
  }
};
</script>
<style lang="scss">
.loading {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.tab {
  padding-bottom: 1.6rem;
  .van-tab__pane {
    min-height: 2rem;
  }
  .pull-refresh {
    min-height: 100vh;
  }
  .van-sticky {
    border-bottom: 1px solid #eee !important;
  }
  .colUl {
    li {
      padding: 0.3rem 0;
      margin: 0 0.3rem;
      border-bottom: 1px solid #eee;
      .col_img {
        height: 2.1rem;
        width: 3rem;
        float: left;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center center !important;
      }
      .col_txt {
        height: 2.1rem;
        margin-left: 3.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h2 {
          font-weight: normal;
          font-size: 0.4rem;
          margin-bottom: 0.1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        p {
          color: #999;
          font-size: 0.32rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .dt {
          font-size: 0.34rem;
          color: #f03535;
        }
      }
    }
  }
}
</style>